@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(satoshi.css);

* {
  font-family: "Satoshi-Regular";
  font-size: 16px;
}
.font-sm {
  font-size: 16px !important;
}
.font-xs {
  font-size: 14px !important;
}

/* Custom-Style-css Start  */

body,
.font-lg,
.font-md,
.font-sm,
.font-xs,
.font-xxs,
.btn,
.btn-link,
.contact-from-area .contact-form-style button,
.card-grid-2 .card-block-info .card-text-price,
.box-newsletter .box-form-newsletter form .font-heading {
  /* font-family: 'Montserrat', sans-serif; */
  font-family: "Satoshi-Medium";
}
h1,
h2,
h3,
h4,
h5,
h6,
.box-newsletter .text-md-newsletter {
  /* font-family: 'Montserrat', sans-serif; */
  font-family: "Satoshi-Variable";
  font-weight: 900 !important;
}
input,
select,
button,
textarea {
  font-family: "Satoshi-Variable";
  font-weight: 900 !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
.font-heading,
.display-1,
.display-2,
.heading-lg,
.heading-sm-1 {
  color: #17144c;
}
.uss-primary {
  color: #17144c !important;
}
.uss-secondary {
  color: #6ecace !important;
}
.banner-hero .heading-banner {
  color: #17144c;
  font-size: 55px;
}
.banner-hero .color-brand-2,
.text-brand-2 {
  color: #6ecace;
  color: #53aeb2;
}
.btn.btn-default {
  /*    background-color: #f00;*/
  background-color: #05264e;
  text-transform: uppercase;
  font-weight: 900;
  border-radius: 8px;
  padding: 13px 25px;
  font-size: 16px;
  line-height: 1;
  transition: 0.2s;
}
.btn.btn-default:hover {
  background-color: #6ecace;
}
.header .main-menu li a:hover,
.header .main-menu li a.active {
  color: #17144c;
}
.header .main-menu li a:hover::before,
.header .main-menu li a.active::before {
  /*    background-color: #f00;*/
  background-color: #05264e;
}
.header .main-menu li.has-children > a::after {
  color: #6ecace;
  color: #53aeb2;
}
.btn-apply,
.box-newsletter .box-form-newsletter form .font-heading {
  padding: 13px 20px 13px 40px;
  background-color: #05264e;
  text-transform: uppercase;
  font-weight: 900;
}
.btn-apply:hover {
  background-color: #6ecace;
}
.box-we-hiring .text-2,
.color-brand-2 {
  color: #6ecace;
  color: #53aeb2;
}
.box-we-hiring .text-hiring {
  color: #17144c;
}
#scrollUp {
  background-color: #17144c;
}
.nav-tabs li a:hover,
.nav-tabs li a.active {
  color: #6ecace;
  color: #53aeb2;
  border-color: #17144c;
}
.card-grid-2 .btn-apply-now {
  color: #6ecace;
  color: #53aeb2;
  background-color: #6ecace;
  color: #fff;
  text-transform: uppercase;
  font-weight: 900;
}
.card-grid-2:hover .btn-apply-now {
  /*    background-color: #f00;*/
  background-color: #05264e;
}
.card-grid-2 .card-block-info .card-text-price {
  color: #17144c;
}
.card-grid-2 {
  background: #f1f1f2;
}
.btn-grey-small {
  background-color: #dee0e4;
}
.find-giver-view-sub-box .btn-grey-small {
  background-color: transparent;
  border: 1px solid #dee0e4;
  border-radius: 8px;
} 
.box-newsletter {
  background: #6ecace;
}
a *:hover {
  /*    color: #f00;*/
  color: #05264e;
}
.btn-brand-1 {
  text-transform: uppercase;
  font-weight: 900;
}
.btn-brand-1:hover {
  /*    background-color: #f00;*/
  background-color: #6ecace;
  color: #ffffff;
}

.preloader img {
  max-width: 120px;
  animation: zoom-in-zoom-out 1s ease-out infinite;
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.cb-container .checkmark:after {
  background: #05264e url(../../public/imgs/template/icons/tick.svg) no-repeat
    center;
}
.cb-container input:checked ~ .checkmark {
  border: 2px solid #6ecace;
}

.card-list-4 .card-price {
  color: #6ecace;
  color: #53aeb2;
}
.btn-send-message {
  background: #f00 url(../../public/imgs/page/company/icon-email.svg) no-repeat
    25px 15px;
}

.btn-editor,
.btn-apply-big {
  /*    background-color: #f00;*/
  background-color: #05264e;
}
.btn-editor:hover,
.btn-apply-big:hover {
  background-color: #6ecace;
}

.card-grid-3 h5 a:hover {
  /*    color: #f00;*/
  color: #05264e;
}

.how_help_icon {
  font-size: 42px;
  color: #6ecace;
  color: #53aeb2;
}

.number-element {
  color: #17144c;
  /* background-color: #6ecace; */
}

.swiper-pagination .swiper-pagination-bullet {
  background-color: #17144c;
}

.box-we-hiring {
  padding: 40px 290px 40px 230px;
}
.home-sec08 .box-we-hiring {
  max-width: 100%;
  padding: 40px 255px 40px 225px;
  align-items: center;
}
.home-sec08 .box-we-hiring .text-1 {
  font-family: "Satoshi-Variable";
}
.home-sec08 .box-we-hiring .text-2 {
  padding: 0px 20px 0px 35px;
  font-family: "Satoshi-Variable";
  font-weight: 700;
}
.home-sec08 .box-we-hiring .text-3 {
  padding: 0px 0px 0px 20px;
}
.box-we-hiring::before {
  width: 165px;
  background: url(../../public/imgs/page/homepage1/bg-left-hiring.png) no-repeat
    0px 0px;
  background-size: contain;
}
.box-we-hiring::after {
  width: 200px;
  margin-right: 15px;
  background: url(../../public/imgs/page/homepage1/bg-right-hiring.png)
    no-repeat 0px 0px;
  background-size: contain;
}
.box-we-hiring .text-hiring {
  font-size: 30px;
}

/* Custom-Style-css End 30980 */

/* New-Style-css Start  */

.suggestions-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  z-index: 1000;
  background-color: #fff;
}

.suggestions-list li {
  padding: 8px;
  cursor: pointer;
  text-align: left;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}

.custom-confirm-button {
  background-color: #05264e;
}

body {
  font-family: "Satoshi-Variable";
  font-family: "Satoshi", sans-serif !important;
  overflow: unset !important;
}
.p-50 {
  padding: 50px;
}

.header {
  padding-top: 15px;
}
.main-header.top-header {
  justify-content: end;
}
.main-header.top-header .verical-line01 {
  margin: 0 10px;
}
.main-header.top-header .header-contact {
  /*    margin-right: 9.3rem;*/
}
.main-header.top-header .header-contact a:hover {
  padding-bottom: 2px;
  border-bottom: 2px solid #3C65F5;
  font-size: 16px;
}
.uss-logo01 {
  width: 25%;
}
.header-logo .uss-logo01 {
  width: 45%;
}
.list-brands a img {
  opacity: 1;
  max-height: 70px;
  width: auto;
}
.service-list01 .card-grid-2 .card-grid-2-image-left .image-box {
  /* width: 70px; */
  width: 100mm;
  min-width: 35mm;
  max-width: 35mm;
  height: 115px;
}
.service-list01 .card-grid-2-image-left .image-box .service-img01 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.btn-send-message {
  background: #05264e url(../../public/imgs/page/company/icon-email.svg)
    no-repeat 25px 15px;
}
.btn-send-message:hover {
  background-color: #6ecace;
}
.banner-hero .block-banner .form-find .box-industry .select2 {
  max-width: 230px;
}
.banner-hero .block-banner .form-find .box-industry {
  max-width: max-content;
}
.banner-hero .block-banner .form-find .box-location {
  width: 75%;
}
.banner-hero.hero-2 {
  background: none;
  padding-top: 0;
  padding-bottom: 0;
}
.banner-hero.hero-2::before {
  opacity: 1;
  z-index: auto;
}
.home-banner-content {
  background-color: #05264ebf;
  height: -webkit-fill-available;
  position: relative;
}
.banner-center-fixed {
  height: -webkit-fill-available;
  position: relative;
}
.banner-center-fixed.d-float-center {
  height: 100%;
}
.home-banner-heros {
  position: relative;
}
.home-banner-content {
  position: absolute;
  top: 0mm;
  padding: 0px;
}
/*Range Slider Start*/
.range-km01 {
  position: relative;
  width: 65%;
  margin: 0px 15px 0px 5px;
  /* padding-left: 10px; */
}
.home-service-list-form .react-datepicker-wrapper {
  width: 100%;
}
.home-service-list-form .react-datepicker__view-calendar-icon input {
  height: 43px;
  margin-top: 5px;
  padding: 6px 10px 5px 35px !important;
}
.home-service-list-form .react-datepicker__input-container .react-datepicker__calendar-icon {
  left: 0;
  top: 10px;
}
.range-km01 input[type="range"] {
  width: 100%;
}
.range-km01 .range-text {
  position: absolute;
  left: 15px;
  top: 15px;
  font-size: 12px;
  color: #7e8081;
  display: none;
}
.range-km01 .range-output {
  position: absolute;
  right: 5px;
  top: 15px;
  font-size: 14px;
  color: #7e8081;
}
/*Range Slider End*/
.australia-map01 {
  position: relative;
  height: 50em;
}
img.australia-img01 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.location-pin-box {
  text-align: center;
}
.location-pin-box img {
  width: 3%;
  position: absolute;
  top: 0;
  text-align: center;
  /* box-shadow: 12px 12px 29px #555; */
}
.location-pin-box .location-pin01 {
  left: 22%;
  top: 43%;
}
.location-pin-box .location-pin02 {
  /* top: 33%; */
  /* left: 47%; */
  top: 45.4%;
  left: 48.5%;
}
.location-pin-box .location-pin03 {
  top: 58%;
  left: 47%;
}
.location-pin-box .location-pin04 {
  top: 45%;
  left: 72%;
}
.location-pin-box .location-pin05 {
  top: 72%;
  left: 72%;
}
.service-list01 .card-grid-2 .card-block-info .color-text-paragraph {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.home-news-events .card-grid-3 .card-block-info p.color-text-paragraph {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.home-news-events .card-grid-3 .card-block-info .news-events-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.service-description01 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.home-news-events .card-grid-3 figure {
  height: 185px;
  width: auto;
}
.home-news-events .card-grid-3 figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-page .service-list01 .card-grid-2 .card-grid-2-image-left {
  padding: 20px 20px 15px 20px;
}

.content-page .service-list01 .card-grid-2 .card-block-info {
  padding: 0px 20px 20px 20px;
}

.content-page .service-list01 .name-job {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.services-view01 .banner-hero.banner-image-single {
  height: 325px;
}

.services-view01 .banner-hero.banner-image-single img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.other-services12 .sidebar-list-job {
  max-height: 195mm;
  overflow: hidden;
  overflow-y: auto;
}
/* 09-08-2024 */
.header-nav .nav-main-menu ul.main-menu .menu-nav-li a {
  font-weight: 800;
}
.h-40 {
  height: 40px !important;
}

.modal .modal-dialog .modal-content button.close {
  border: 0;
  background-color: #17144c;
  border-radius: 50px !important;
  color: #fff;
  padding: 3px 8px;
  line-height: 1.5;
}

.modal .modal-dialog .modal-content button.close:hover {
  background-color: #6ecace;
  color: #fff;
}
.modal-icon-title {
  display: flex;
  align-items: center;
  gap: 15px;
  line-height: 1.2;
}
.uss-new-arraival {
  width: 15%;
}

.d-float-center {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-flow: wrap;
}
.gap-15 {
  gap: 15px;
}

.form-mb-0 {
  margin-bottom: 0rem !important;
}

.form-text-danger {
  color: #e30000;
}

.form-text-danger:hover {
  color: #17144c;
  text-decoration: underline;
}

.badge {
  padding: 0.5em 0.8em;
}

.light.btn-primary {
  background-color: #e1e7fc;
  border-color: #e1e7fc;
  color: #05264e;
  border-radius: 1.25rem;
}

.light.btn-primary:hover {
  background-color: #05264e;
  border-color: #05264e;
  color: #fff;
}

.card-grid-5 .box-cover-img .content-bottom {
  left: 15px;
  right: 5px;
}

.find-by-location .card-image-top .image img {
  object-fit: cover;
  height: 100%;
}

/* .footer .icon-socials.icon-instagram {
  background: url(../home/imgs/template/icons/insta.svg) no-repeat 0px 0px;
  transition-duration: 0.2s;
} */

.sidebar-border,
.sidebar-shadow {
  border: 1px solid #e0e6f7;
  padding: 25px;
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 40px;
}

.card-list-4 {
  position: relative;
  display: flex;
  width: 100%;
  padding: 0px 0px 15px 0px;
  margin-bottom: 0px;
  border-bottom: 1px solid #e0e6f7;
}
.card-list-4 .image {
  min-width: 60px;
  padding-right: 10px;
  width: 30px;
  border-radius: 10px;
  /* height: 10px; */
}
.content-single h3,
.content-single h4,
.content-single h5 {
  margin-top: 20px;
  color: #4f5e64;
  font-weight: bold;
  margin-bottom: 20px;
}
strong {
  font-weight: bold;
}
/* pagination */
.custom-pagination {
  justify-content: center; /* Center pagination in its container */
  margin: 20px 0; /* Space around pagination */
}

.custom-pagination .page-item {
  margin: 0 5px; /* Space between page numbers */
}

.custom-pagination .page-link {
  border-radius: 0; /* Rounded corners removed for a modern look */
  padding: 10px 15px; /* Comfortable padding */
}

.custom-pagination .page-item.active .page-link {
  background-color: #007bff; /* Bootstrap primary color */
  border-color: #007bff; /* Match border with background color */
  color: #fff; /* Text color for active page */
}

.custom-pagination .page-link:hover {
  background-color: #0056b3; /* Darker blue for hover effect */
  color: #fff; /* Text color for hover effect */
}

/* Service Banner */
.banner-hero.banner-single::before,
.banner-hero.banner-single::after {
  display: none;
}
.banner-hero .block-banner .form-find .form-input {
  height: 48px;
}
.range-km01 {
  /* width: 30%; */
}
.banner-hero .block-banner .form-find .box-industry .select2 {
  max-width: 100%;
}
.box-location01 {
  min-width: 20%;
}
/* Service Banner end */

/* New-Style-css End 31172  */

/* Responsive-css Start min 769  */
.preloader img {
  max-width: 50px;
  animation: zoom-in-zoom-out 1s ease-out infinite;
}
.preloader img {
  max-width: 50px;
  animation: zoom-in-zoom-out 1s ease-out infinite;
}
.preloader img {
  max-width: 150px;
}

/*faqs css start*/
.faq-breadcrumb {
  padding: 50px 0;
}
.faqs-imgs .faqs-1 {
  border-radius: 80px 80px 80px 0;
}
.faqs-imgs .faqs-2 {
  border-radius: 0 80px 0 80px;
}
.faqs-imgs .faqs-3 {
  border-radius: 0 80px 80px 80px;
}
.panel,
.panel-body {
  box-shadow: none;
}

.panel-group .panel-heading {
  padding: 0;
  background-color: #f5f5f5;
  border: 1px solid #eee;
  border-color: #ddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.panel-group .panel-heading a {
  display: block;
  padding: 7px 15px;
  text-decoration: none;
  position: relative;
}

.panel-group .panel-heading a:after {
  content: "-";
  float: right;
  font-size: 30px;
  line-height: 0.49;
}

.panel-group .panel-heading a.collapsed:after {
  content: "+";
  font-size: 25px;
  line-height: 0.9;
  cursor: pointer;
}
.panel-group .panel-heading a:after {
  content: "-";
  float: right;
  font-size: 30px;
  line-height: 0.49;
  cursor: pointer;
}

.faq-accordian .card .collapse.show .card-body {
  font-weight: 600;
  color: black;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
}
.page-titles {
  padding-top: 0;
  margin-bottom: 5px;
}
.faq-pages {
  margin-top: "500px";
}

.faq-company01 .faq-div01 .panel-default {
  border-color: #ddd;
  background-color: #fff;
  border-radius: 4px;
  margin-top: 8px;
}
.faq-div01 h3 {
  color: #17144c;
  /* font-weight: 800 !important; */
  margin-top: 20px;
  margin-bottom: 10px;
}
.faq-company01 .faq-div01 .panel-group .panel-heading a.collapsed:after {
  line-height: 1.2;
}
.faq-div01 .panel-group .panel-title a {
  font-weight: 700;
  color: #17144c;
  font-size: 18px;
}
.faq-div01 .panel-group .panel-title a:hover {
  cursor: pointer;
  color: #6ecace;
}

.faq-div01 .panel-group .panel-body {
  color: #333;
  padding: 15px 50px 15px 15px;
  border: 1px solid #d5d2d2;
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: 500 !important;
  font-family: "Satoshi-Medium";
  /* font-size: 15px; */
  line-height: 1.58;
}

/* Faq css end  */

.breadcrumb {
  padding: 10px 15px;
  background-color: #17144c;
  /* background-color: #6ecace; */
}
.our-support-swiper .slick-track {
  display: flex;
  gap: 30px;
}
.custom-checkbox {
  transform: scale(1.3); /* Adjust the scale as per your requirement */
  margin-right: 10px; /* Adds spacing between checkbox and label */
}
.banner-hero .block-banner .form-find .form-input {
  padding: 10px 20px 10px 25px;
}
.banner-hero .block-banner .form-find .box-industry,
.banner-hero .block-banner .form-find .input-industry {
  background: transparent !important;
  background-color: transparent !important;
  font-weight: 500 !important;
}

.box-location div.css-rlod8t-control .css-hlgwow .css-19bb58m {
  margin: 0;
  height: 48px;
}
.banner-hero .block-banner .list-tags-banner {
  position: initial;
}
.box-location div.css-rlod8t-control input {
  height: 44px;
}
.box-location
  div.css-rlod8t-control
  .css-1wy0on6
  .css-1u9des2-indicatorSeparator {
  visibility: hidden;
}
.css-1jqq78o-placeholder {
  font-weight: 400;
}
.range-km01 .range-text {
  font-weight: 600;
}
.range-km01 .range-output {
  /* font-family: "Plus Jakarta Sans", sans-serif; */
  font-weight: 600;
  font-size: 12px;
}
.home-service-list-form .box-industry:before {
  content: "";
  background: url(../imgs/template/icons/industry.svg) no-repeat;
  width: 18px;
  height: 18px;
  position: absolute;
  left: 5px;
  top: 15px;
}
.service-list-form .box-industry:before {
  content: "";
  background: url(../imgs/template/icons/industry.svg) no-repeat;
  width: 18px;
  height: 18px;
  position: absolute;
  left: 15px;
  top: 26px;
}
.service-list-form .react-datepicker__calendar-icon {
  top: 8px;
  left: 5px;
}
.service-list-form .range-km01 {
  width: 80%;
}
.box-newsletter .box-form-newsletter form {
  position: relative;
}
.box-newsletter .box-form-newsletter form span.text-danger {
  width: 72%;
  padding-right: 18px;
  position: absolute;
  bottom: 0px;
  text-align: end;
}
footer.footer {
  background-color: #f2f4fa;
  padding-top: 50px;
}
footer.footer .color-text-paragraph-2 {
  color: #373f4f;
}
.footer .footer-bottom {
  border-top: 1px solid #c6c9d1;
}
.footer-uss-logo01 {
  width: 25%;
}
footer.footer div .grayscale-img:hover {
  opacity: 0.8;
}
.f-18 {
  font-size: 18px !important;
}
.f-15 {
  font-size: 15px !important;
}
.f-13 {
  font-size: 13px !important;
}
.find-care-givers .care-giver-lists {
  display: grid;
}
.find-care-givers .care-giver-lists .card-grid-2 .card-grid-2-image-left {
  padding: 25px 15px 15px 15px;
}
.find-care-givers .care-giver-lists .card-grid-2 .card-grid-2-image-left .image-box {
  min-width: 90px;
  max-width: 90px;
  height: 80px;
  border-radius: 10px;
  padding-right: 10px;
}
.service-list01.care-giver-lists .card-grid-2 .card-grid-2-image-left .image-box {
  /* width: 70px; */
  width: 100mm;
  min-width: 35mm;
  max-width: 35mm;
  height: 115px;
}
.service-list01.care-giver-lists .card-grid-2 .card-grid-2-image-left .right-info .location-small {
  padding: 0px 0px 0px 17px;
  font-size: 13px;
}
.find-care-givers
  .care-giver-lists
  .card-grid-2
  .card-grid-2-image-left
  .image-box
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.find-care-givers
  .care-giver-lists
  .card-grid-2
  .card-grid-2-image-left
  .right-info
  .name-job {
  line-height: 22px;
  margin-bottom: 5px;
}
.find-care-givers .care-giver-lists .star-rating ul li i {
  font-size: 17px;
  margin-left: 0;
}
.find-care-givers .care-giver-lists .card-block-info .contact-info span {
  font-size: 13px;
  color: #a0abb8;
  font-weight: 400;
}
.find-care-givers .care-giver-lists .card-block-info .contact-info span i {
  font-size: 14px;
  color: #a0abb8;
  margin-left: 0;
}
.bg-outline-success {
  border: 1px solid #17a600;
  color: #17a600;
  background-color: transparent;
}
.view-care-givers span {
  font-size: 16px;
  color: #4f5e64;
  font-weight: 400;
}
ul.list-comments {
  list-style: none;
}
.list-comments .person-img {
  width: 15mm;
  height: 15mm;
  border: 1px solid #eee;
  border-radius: 50px;
  margin-right: 15px;
}
.list-comments .person-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px;
}
.btn-border.active {
  color: #ffffff;
  background-color: #05264e;
  transition-duration: 0.2s;
  transform: translateY(-3px);
}
.content-single .list-comments p {
  margin-bottom: 0px;
}
.list-comments li ul li {
  padding-left: 0px;
  list-style: none;
}
.content-single .list-comments .star-rate-sub {
  padding-left: 0px;
}
.view-care-givers .box-company-profile {
  max-width: 100%;
  padding-top: 0;
}
.view-care-givers .box-company-profile .image-compay {
  width: 32mm;
  height: 30mm;
  border: 1px solid #eee;
  border-radius: 15px;
  margin-right: 15px;
  position: relative;
  top: 0;
}
.view-care-givers .box-company-profile .image-compay img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}
.view-care-givers .box-nav-tabs ul.nav {
  border-bottom: 1px solid #dee2e6;
}
.view-care-givers .box-nav-tabs ul.nav .nav-item {
  margin-bottom: -2px;
}
.view-care-givers .box-nav-tabs ul.nav .nav-link {
  border-radius: 0;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #6c757d;
}
.view-care-givers .box-nav-tabs ul.nav .nav-link.active {
  color: #53aeb2;
  font-size: 14px;
  box-shadow: none;
}
.btn-brand-1 {
  background-color: #05264e;
  border-radius: 8px;
  padding: 15px 25px;
  font-size: 14px;
  line-height: 1;
  transition: 0.2s;
  letter-spacing: 1.09px;
}
.btn-default2 {
  background-color: #53aeb2;
  color: #fff;
  border-radius: 8px;
  padding: 15px 25px;
  font-size: 14px;
  line-height: 1;
  transition: 0.2s;
  letter-spacing: 1.09px;
  text-transform: uppercase;
  font-weight: 900;
}
.btn-default2:hover {
  background-color: #05264e;
  color: #fff;
}
body
  div:where(.swal2-container)
  button:where(.swal2-styled):where(.swal2-confirm) {
  border-radius: 8px;
  padding: 15px 35px;
  font-size: 14px;
  line-height: 1;
  transition: 0.2s;
  background-color: #05264e;
}
body
  div:where(.swal2-container)
  button:where(.swal2-styled):where(.swal2-confirm):hover {
  background-color: #6ecace;
}
.giver-list-right {
  padding-right: 0;
}
.care-giver-lists .card-grid-2 .btn-apply-now {
  padding: 10px 10px;
}
.care-giver-lists .right-info .location-small {
  display: inline-block;
  padding: 8px 0px 0px 10px;
  font-size: 14px;
  color: #8f9192;
  font-weight: 500 !important;
  font-family: "Satoshi-Medium";
}
.speak-out {
  color: #53aeb2;
}

/*How it work css start*/

.uk-timeline .uk-timeline-item {
  display: flex;
  position: relative;
}
.uk-timeline .uk-timeline-item::before {
  background: #dadee4;
  content: "";
  height: 100%;
  height: -webkit-fill-available;
  left: 19px;
  position: absolute;
  top: 40px;
  width: 2px;
  z-index: 0;
}
.uk-badge {
  box-sizing: border-box;
  min-width: 22px;
  height: 22px;
  padding: 0 5px;
  border-radius: 500px;
  vertical-align: middle;
  background: #05264e;
  color: #fff;
  font-size: 0.875rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.uk-timeline .uk-timeline-item .uk-timeline-icon .uk-badge {
  margin-top: 0px;
  width: 40px;
  height: 40px;
}
.uk-timeline .uk-timeline-item .uk-timeline-content {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0 0 0 1rem;
}
.uk-card {
  position: relative;
  box-sizing: border-box;
  transition: box-shadow 0.1s ease-in-out;
}
.uk-card-default {
  background: #fff;
  color: #666;
  box-shadow: 0 5px 15px #00000042;
}
.uk-overflow-auto {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.uk-margin-medium-bottom {
  margin-bottom: 40px !important;
}
.uk-timeline .uk-timeline-item .uk-card {
  max-height: 300px;
  border: 1px solid #eee;
}
.uk-card-header {
  padding: 15px 30px;
}
.uk-card-default .uk-card-header {
  border-bottom: 1px solid #e5e5e5;
}
.uk-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}
.uk-grid-column-small,
.uk-grid-small {
  margin-left: -15px;
}
.uk-flex-middle {
  align-items: center;
}
.uk-card-body > :last-child,
.uk-card-footer > :last-child,
.uk-card-header > :last-child {
  margin-bottom: 0;
}
.uk-card-title {
  font-size: 1.5rem;
  line-height: 1.4;
  text-transform: uppercase;
}
.uk-card-default .uk-card-title {
  color: #333;
}
.uk-card-body {
  padding: 30px 30px;
}
.uk-overflow-auto > :last-child {
  margin-bottom: 0;
}
.btn-sm {
  padding: 12px 20px !important;
  font-size: 14px !important;
}
.services-view01 {
  text-transform: capitalize;
}
.sentence-case {
  text-transform: lowercase;
}
.sentence-case:first-letter,
.sentence-case::first-letter,
.sentence-case:first-line,
.sentence-case::first-line {
  text-transform: uppercase;
}
.services-view01 .content-single p {
  margin-bottom: 5px;
  font-weight: 400 !important;
  font-family: "Satoshi-Medium";
}
.services-view01 .content-single p strong {
  font-weight: 400 !important;
  font-family: "Satoshi-Medium";
}
.services-view01 .content-single p br {
  display: none;
}
.other-services12 .similar-service-ul01 .card-list-4 .card-price span {
  font-weight: 700;
  font-size: 14px;
}
.services-view01 .btn-apply-big.btn-apply-icon {
  background-position: 21px 12px;
}
.view-care-givers .fc .fc-button .fc-icon {
  color: #fff;
}
.contact-form-style textarea {
  font-weight: 500 !important;
  font-family: "Satoshi-Medium";
  color: #000;
}
.contact-form-style input {
  font-weight: 500 !important;
  font-family: "Satoshi-Medium";
  color: #000;
}

/* About css start  */
.about-ul li {
  color: #4f5e64;
  font-weight: 500;
  line-height: 1.65;
}
.about-ul li span {
  font-weight: 600;
}
.about-tabs-div .nav-tabs .nav-link {
  text-transform: uppercase;
  color: #4f5e64;
}
.about-tabs-div .nav-tabs {
  display: flex;
}
.accordion-about .accordion-header button {
  line-height: initial;
}
/* About css End  */

/* Registration Full Screen Modal css start  */
.andriod-ios-app-download {
  display: flex;
  height: 100%;
  align-content: center;
}
.download-app-popup .modal-header .btn-close {
  background-color: #6ecace;
  opacity: 1;
  border-radius: 50px;
  font-size: 15px;
  padding: 10px;
  top: 25px;
  right: 25px;
}
.download-app-popup .modal-header .btn-close:hover {
  opacity: 0.7;
}
.download-app-popup .head-section .app-link-head {
  font-family: "Satoshi-Variable";
  font-weight: 900 !important;
  font-size: 62px;
  color: #05264e;
  text-align: center;
  line-height: 1.1;
}
.download-app-popup .head-section .app-link-head .logo img {
  width: 60px;
  height: 60px;
}
.download-app-popup .head-section .app-link-head .site-name {
  color: #6ecace;
}
.download-app-popup .app-link-content .sub-text1 {
  margin: 5px 0 5px;
}
.download-app-popup .app-link-content .sub-head2 {
  margin: 5px 0 5px;
}
.download-app-popup .app-scanner-box {
  margin: 15px auto 0;
}
.download-app-popup .app-scanner-box .qr-box {
  width: 26vh;
  background-color: #05264e;
  border: 1px solid #05264e;
  color: #fff;
  border-radius: 20px;
  text-align: center;
  padding: 15px 15px;
}
.download-app-popup .app-scanner-box.app-right .qr-box {
  background-color: #05264e;
  border: 1px solid #05264e;
}
.download-app-popup .app-scanner-box .qr-box img {
  border-radius: 12px;
}
.download-app-popup .app-scanner-box .qr-box .get-app-text {
  color: #fff;
  font-weight: 600 !important;
  font-family: "Satoshi-Medium";
  margin-top: 10px;
}
.download-app-popup .app-scanner-box .qr-box:hover {
  opacity: 0.7;
}
.download-app-popup .app-scanner-box .qr-box:hover .get-app-text {
  text-decoration: underline;
}
/* Registration Full Screen Modal css end  */

/*How it work css end*/
/* .breadcrumb {
    padding: 8px 15px;
    margin-bottom: 20px;
    list-style: none;
    background-color: #f5f5f5;
    border-radius: 4px;
}
.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0;
    margin-bottom: 1rem;
    list-style: none;
} */

kb-data-box {
  width: 100%;
  flex: 1;
}

.kb-modal-data-title {
  margin-bottom: 10px;
}
.kb-data-title h6 {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
}
.kb-file-upload {
  margin-bottom: 20px;
}
.file-upload-box {
  border: 1px dashed #b6bed1;
  background-color: #f0f2f7;
  border-radius: 4px;
  min-height: 150px;
  position: relative;
  overflow: hidden;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8194aa;
  font-weight: 400;
  font-size: 15px;
}
.file-upload-box .file-upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.file-link {
  color: #475f7b;
  text-decoration: underline;
  margin-left: 3px;
}
.file-upload-box .file-link:hover {
  text-decoration: none;
}
.file-atc-box {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.file-image {
  /* width: 130px;
  min-width: 130px;
  height: 85px;
  min-height: 85px; */
  width: 130px;
  height: 85px;
  background-size: cover;
  border-radius: 5px;
  margin-right: 15px;
  background-color: #eaecf1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #475f7b;
  padding: 3px;
}
.file-image img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
}
.file-detail {
  flex: 1;
  width: calc(100% - 210px);
}
.file-detail h6 {
  word-break: break-all;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}
.file-detail p {
  font-size: 12px;
  color: #8194aa;
  line-height: initial;
  font-weight: 400;
  margin-bottom: 8px;
}
.file-actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}
.file-action-btn {
  font-size: 12px;
  color: #8194aa;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  margin-right: 15px;
  cursor: pointer;
}
.file-action-btn:hover {
  color: #3d546f;
  text-decoration: underline;
}
.file-atc-box:last-child {
  margin-bottom: 0;
}
.find-giver-chat-btn {
  padding-left: 55px;
}
.find-giver-row .chat-popup {
  background-color: #121212c2;   
  left: 0; 
  top: 0;
  bottom: auto;
  width: 100%;
  height: 100%;
}
.find-giver-chat-form {
  width: 35%;
  margin: auto;
  margin-top: 25px;
  position: relative;
}
.find-giver-row .chat-popup button.chat-modal-close {
  position: absolute;
  right: -15px;
  font-size: 26px;
  padding: 10px;
  border-radius: 10px;
  background-color: #6ecace;
  border-color: #6ecace;
  color: #fff;
}
.find-giver-row .chat-popup button.chat-modal-close:hover {
  background-color: #05264e;
  border-color: #05264e;
  color: #fff;
}
.find-giver-row .chat-popup .chatApp__conv {
  background-color: #fff;
  border-radius: 8px;
}
.find-giver-row .chat-popup .chatApp__conv .chatApp__convTitle {
  width: 100%;
  color: #333;
  padding: 20px 15px 0;
}
.find-giver-row .chat-popup .chatApp__convTimeline {
  height: 365px;
}
.sub-box-content {
  /* display: grid; */
  /* border-right: 1px solid #eee; */
}
.view-care-givers .giver-sub-head {
  border-left: 5px solid #53aeb2;
    border-radius: 3px;
    padding-left: 8px;
    line-height: normal;
    color: #66789C;
    font-family: 'Satoshi-Medium';
    font-size: 16px;    
    font-weight: 400!important;
}
.care-giver-sub-link {
  line-height: 1.3;
  font-weight: 900;
  font-size: 14px;
  color: #0d6efd;
  font-family: 'Satoshi-Bold';
  text-decoration: underline;
}
.view-care-givers .care-giver-sub-link:hover {
  text-decoration: underline;
}
.view-care-givers .care-giver-sub-list {
  color: #05264E;
  font-size: 14px;
  line-height: 1.3;
}
.modal .slect-time-slots .modal-header button.close {
  border-radius: 5px !important;
  background-color: #6ecace;
  color: #fff;
}
.modal .slect-time-slots .modal-header button.close:hover {
  background-color: #17144c;
  color: #fff;
}
.btn-unavailable, .btn-unavailable:hover {
  font-size: 12px;
  padding: 7px 10px;
  background-color: transparent;
  border: 1px solid #eb3042;
  border-radius: 8px;
  color: #eb3042;  
  cursor: text;
}
.btn-available, .btn-available:hover {
  font-size: 16px;
  padding: 7px 10px;
  background-color: transparent;
  border: 1px solid #c9c9c9;
  border-radius: 8px;
  color: #000;
  cursor: text;
}
form.otp-verification-modal .css-hlgwow {
  height: 53px;
}
form.otp-verification-modal .css-19bb58m {
  height: 47px;
}
.available-box .soft-green {
  /* background-color: #6ecace33; */
}
.unavailable-box .soft-grey, .available-box .soft-green {
  border-radius: 5px;
}
.soft-grey {
  /* background-color: #F8F8FB; */
}
.availability-row .btn-sm {
  padding: 6px 12px !important;
  font-size: 12px !important;
}
.modal .slect-time-slots p.show-date-time {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 5px;
  color: #333;
}
.modal .slect-time-slots p.show-content {
  font-size: 17px;
}
.modal .slect-time-slots .calender-time-right .unavailable-box .day-comment {
padding-left: 10px;
}
.modal .slect-time-slots .calender-time-right .unavailable-box .day-comment li .unavilable-text {
font-weight: 600;
}
.calender-time-right .react-datepicker-wrapper {
  width: 100%;
}
.calender-time-right .react-datepicker__input-container .react-datepicker__calendar-icon {
  padding: 1rem 0.5rem;
}
.hour-rate span {
  font-weight: 600;
}
/* .care-giver-lists .right-info .location-small {
  display:inline-block;
  text-transform: lowercase;  
}
.care-giver-lists .right-info .location-small::first-letter {  
  text-transform: uppercase!important;
} */
/* NDIS */

.ndis-page .header-logo {
  text-align: end;
}
.ndis-page .uss-logo01 {
  width: 30mm;
}
.ndis-table th, .ndis-table td {
  vertical-align: middle;
}
.hr-main-ndis {
  height: 3px;
  opacity: 1;
  margin-top: 0.6rem;
}
.hr-sub-main-ndis {
  height: 3px;
  opacity: 1;
  margin-top: 1.5rem;
  margin-bottom: 0rem;
}
.ndis-page .card-header {
  padding-top: 10px!important;
  padding-bottom: 10px!important;
}
.ndis-page .ndis-booking-status {
  border: 1px solid #00000008;
  background-color: #00000008;
  padding: 15px 20px;
}
.ndis-booking-status .output {
  font-weight: 600;
}
.ndis-table .textarea {
  min-height: 40mm;
}
.ndis-table .service-type {
  font-size: 20px;
  font-weight: 600;
  color: #05264e;
  margin-bottom: 5px;
}
.ndis-table .box-head01 {
  color: #6ecace;
}
.ndis-table .task-list ul {
  list-style: outside;
}
.ndis-table .task-list ul li {
  margin-left: 15px;
  margin-right: 15px;
}
.ndis-table .ndis-booking-info .input {
  font-weight: 500;
}