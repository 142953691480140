* {
    font-family: 'Satoshi-Medium';
}

.float-left{
    float: left!important;
}

.uss-bg-primary{
    background-color: #17144c;
}

.uss-bg-info{
    background-color: #6ecace;
}

.uss-bg-dark{
    background-color: #333;
}

.uss-text-primary{
    color: #17144c;
}
.uss-text-info{
    /* color: #6ecace; */
    color: #53aeb2;
}

.text-dark01 {
    color: #000;
}

.btn-default2 {
    background-color: #53aeb2;
    color: #fff;
    border-radius: 8px;
  padding: 15px 25px;
  font-size: 14px;
  line-height: 1;
  transition: 0.2s;
  letter-spacing: 1.09px;
    text-transform: uppercase;
    font-weight: 900;
}
.btn-default2:hover {
    background-color: #05264E;   
    color: #fff;
}
.login-page01 .btn {
    padding: 14px 25px;
}

.fw-600{
    font-weight: 600;
}

.border-b01{
    border-bottom: 1px solid #eee;
}

.card-blog {
    margin-top: 30px;
    margin-bottom: 30px;
}

.card-guest {
    background: #6ecace;
}

.card .table {
    padding: 25px 30px 40px;
}

.card-guest hr {
    border-color: #fff!important;
    opacity: 1;
}

.card-guest p, .card-organizer p {
    color: #000;
    font-weight: 600;
    font-size: 17px;
}

.card-organizer {
    background: #f7f7f7;
}

.btn1 {
    font-weight: 700;
    font-size: 18px;
    padding: 15px 30px;
    border-radius: 50px;
}

.btn1:hover{
    background-color: #181460;
}

.care-seeker01, .care-giver01{
    width: 100%;
    height: auto;
}

.boxPoints{
    font-weight: 800; 
    font-size: 14.5px; 
    color: #333; 
}

/* Form Wizard */

.stepwizard-step p {    
    margin-top: 10px;
}

.stepwizard-row {
    display: table-row;
}

.stepwizard {
    display: table;
    width: 100%;
    position: relative;
    margin-bottom: 1.5rem;
}

.stepwizard-step button[disabled] {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
}

.stepwizard-row .stepwizard-step:before {
    top: 14px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 2px;
    background-color: #ccc;   
    z-index: -1;
    margin-left: 15px;

}
.stepwizard-row .stepwizard-step:last-child::before {
    /* display: none; */
    visibility: hidden;
}
.stepwizard-step.success:before {
    background-color: #181460;
}
.stepwizard-step {
    display: table-cell;
    text-align: center;
    position: relative;
    z-index: 1;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

.wizard-form01 .stepwizard .stepwizard-row .stepwizard-step .btn-default{
    background-color: #6ecace;
    border-color: #6ecace;
    line-height: 2;
    padding: 0;
}

.wizard-form01 .stepwizard .stepwizard-row .stepwizard-step .btn-primary{
    background-color: #181460;
    border-color: #181460;
    padding: 6px 0;
}

.wizard-subtitle01{
    font-size: 18px;
    font-weight: 800;
    text-transform: uppercase;
    
    color: #000;
}

.wizard-form01 form .setup-content .form-group label{
    font-weight: 600;
}

.wizard-form01 form .setup-content .btn-block {
    width: 100%;
    border-radius: 50px;
}

.select-identity{
    display: flex;
    margin-left: 0.5rem;
}

.select-identity .form-check-input{
    width: 1em;
    height: 1em;
    padding-left: 0;
}

.availability-table01 th{
    vertical-align: middle;
    
    font-weight: 900;
}

.availability-table01 td{
    vertical-align: middle;
    
    font-weight: 600;
}

.table-head01{
    width: fit-content;
    float: left;
        
    font-weight: 800;
    font-size: 18px;
}

.table-p02{
    
    font-weight: 700;
    font-size: 15px;
}

.table-time02{
    font-weight: 700;
    color: #000;
}

.form-subtitle01{
    
    font-weight: 700;
    font-size: 16px;
    color: #000;
}

.form-subtitle02{
    
    font-weight: 500;
    font-size: 14px;
}

.plus-div01 {
    align-self: center;
    padding-left: 0px;
    padding-right: 0px;
}

.plus-div01 .plus-icon01 i{
    color: #17144c;
    font-size: 20px;
    border: 1px solid #17144c;
    border-radius: 50px;
    padding: 3px 6px 2px;
    margin-top: 9px;
}

.plus-div01 .plus-icon01:hover i{
    background-color: #6ecace;
    border: 1px solid #6ecace;
    color: #fff;
}

.trash-div01{
    margin-top: -25px;
}

.trash-div01 .plus-icon01 i{
    color: #FF4C41;
    border: 1px solid #FF4C41;
}

.trash-div01 .plus-icon01:hover i{
    background-color: #FF4C41;
    border: 1px solid #FF4C41;
    color: #fff;
}

.row-step04 .form-group input{
    font-size: 14px;
    padding: 10px 10px 10px 10px;
    height: 40px;
}

.row-step04 .form-group .form-select{
    font-size: 14px;
    background-position: right 0.25rem center;
    padding: 8px;
    height: 40px;
}

.row-step04 .form-group .readonly{
    color: #333;
    cursor: auto;
}

.yr-readonly{
    text-align: center;
}

.form-group-step05 .select-identity .custom-control-input{
    width: 15px;
    float: left;
    height: auto;
    margin-top: 5px;
}

.form-group-step05 .select-identity .form-check:first-child{
    padding-left: 0rem;
}

.form-group-step05 .select-identity .form-check{
    font-size: 13px;
    /* padding-left: 1rem; */
}

/* Payment Details  */

.basic-rate01 .list-group .list-group-item:first-child{
    border-top: 0;
}

.basic-rate01 .list-group .list-group-item{
    padding: 0.65rem 1rem;
    border-left: 0;
    border-right: 0;
    
}

.basic-rate01 .list-group .list-group-item:last-child{
    border-bottom: 0;
}

.basic-rate01 .rates-dollar01{
    font-size: 20px;
    font-weight: 800;
}

.basic-rate01 .rates-day01{
    font-size: 14px;
    font-weight: 500;
}

.basic-rate01 .rate-conent01{
    font-size: 12px;
    font-weight: 600;
    color: #6ecace;
    color: #53aeb2;
}

/* Toogle Switch css */

input[switch] {
    display: none;
  }
  input[switch] + label {
    font-size: 1em;
    line-height: 1;
    width: 3.5rem;
    height: 1.5rem;
    background-color: #ddd;
    background-image: none;
    border-radius: 2rem;
    padding: 0.1666666667rem;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    position: relative;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2) inset;
    font-family: inherit;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }
  input[switch] + label:before {
    /* Label */
    text-transform: uppercase;
    color: #333;
    content: attr(data-off-label);
    display: block;
    font-family: inherit;
    font-family: FontAwesome, inherit;
    font-weight: 700;
    font-size: 0.7rem;
    line-height: 1.22rem;
    position: absolute;
    right: 0.2166666667rem;
    margin: 0.2166666667rem;
    top: 0;
    text-align: center;
    min-width: 1.6666666667rem;
    overflow: hidden;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }
  input[switch] + label:after {
    /* Slider */
    content: '';
    position: absolute;
    left: 0.1666666667rem;
    background-color: #f7f7f7;
    box-shadow: none;
    border-radius: 2rem;
    height: 1.22rem;
    width: 1.22rem;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }
  input[switch]:checked + label {
    background-color: #181460;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.15)), to(rgba(0, 0, 0, 0.2)));
    background-image: linear-gradient(rgba(255, 255, 255, 0.15), rgba(0, 0, 0, 0.2));
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3) inset;
  }
  input[switch]:checked + label:before {
    color: #fff;    
    content: attr(data-on-label);
    right: auto;
    left: 0.2166666667rem;
  }
  input[switch]:checked + label:after {
    left: 2.2rem;
    background-color: #f7f7f7;
    box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.3);
    top: 2px;
  }

  .availability-table01 .border-b01 .input-group .input-group-prepend .input-group-text{
    height: 48px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .confirm-team-single01 .xs-margin-30px-bottom{
    text-align: center;
  }

  .confirm-img01{
    width: 100px;
    height: 100px;
  }

  .uss-logo01 {
    width: 45%;
}
.account-verified .card {
    /* box-shadow: 12px 12px 55px #55555545; */
    border-radius: 0;
}
.account-verified .header-logo .uss-logo01 {
    width: 25%;
}
.account-verified .block-subTitle {
    font-size: 34px;
}
.authincation form [type="checkbox"], .login-page01 form [type="checkbox"] {
    width: 16px;
    height: 16px;
}
.login-page01 form .react-datepicker-wrapper {
    width: 100%;
}
.login-page01 form .react-datepicker-wrapper .react-datepicker__input-container .react-datepicker__calendar-icon {
    padding: 1.1rem 1rem;
}
.react-datepicker__view-calendar-icon input {
    padding: 6px 10px 5px 40px!important;
}

/* Care seeker registration form css start */
.seeker-registration-form1 .wrapper {
    border-radius: 6px;
    border-color: #198754;
}
.seeker-registration-form2 textarea {
    font-weight: 500 !important;
    color: #333;
}
.form-control {
    color: #333;
    font-weight: 500 !important;
}
.login-page01 .wrapper input {
    color: #333;
    font-weight: 500 !important;
}
.seeker-registration-form4 .out-put {
    font-weight: 500 !important;
    font-family: 'Satoshi-Medium';
    color: #000;
}
.seeker-registration-form4 {
    text-transform: capitalize;
}
/* care seeker registration form css end */

/* Care Giver registration form css start  */
.seeker-registration-form2.giver-register4 .wrapper {
    border-radius: 6px;
}
.form-profile-pic .profile-pic-div.profile-preview {
    background-color: #eee;
    padding: 20px 15px 10px;
}
.form-profile-pic .profile-pic-div.profile-preview img {
    background-color: #fff;
    padding: 2px;
    border-radius: 5px;
    box-shadow: 12px 12px 55px #55555524;
}
.seeker-registration-form2.giver-register4 .service-radius-formgroup .pre-box-sec01 {
    width: 25px!important;
    border-radius: 150px!important;
}
.range-slider .range-slider-formgroup {
    margin-bottom: 0!important;
}
.seeker-registration-form2.giver-register4 .form-switch {
    display: flex;
    align-items: center;
    gap: 5px;
}
.seeker-registration-form2.giver-register4 .form-switch .form-check-input {
    width: 3em;
    height: 1.5em;
    margin-top: -2px;
}
.extra-hrs-switch input {
    margin-top: 1px;
    width: 2.5rem !important;
    height: 1.2rem;
}
.giver-form-final .out-put {
        font-weight: 500 !important;
        font-family: 'Satoshi-Medium';
}
/* Care Giver registration form css end  */
.text-math-auto {
    text-transform: math-auto!important;
}
.react-tel-input .form-control{
    border: 1px solid;
    border-radius: 4px;
    height: 50px;
    box-shadow: none;            
    font-size: 14px;
    width: 100%;
  } 
.react-tel-input .form-control {
    border-color: #198754;
    padding-right: calc(1.5em + .75rem);
    background-image: url(../../src/imgs/tik-green.svg);
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  }
  .react-tel-input .form-control.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem);
    background-image: url(../../src/imgs/info-red.svg);
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  }