/* Responsive-css Start  */

@media screen and (min-device-width: 769px) and (max-device-width: 3000px) {
    .view-care-givers .box-nav-tabs ul.nav .nav-link {        
        font-weight: 900 !important;
        font-size: 28px;
        font-size: 16px;
    }
    .view-care-givers .box-nav-tabs ul.nav .nav-link.active {
        /* color: #17144c; */
        font-family: 'Satoshi-Variable';
        font-weight: 900 !important;
        font-size: 17px;
    }
    .download-app-popup .head-section .app-link-head {
        padding: 0 310px;
    }
    .download-app-popup .app-link-content {
        padding: 0 395px;
      }
      .download-app-popup .app-scanner-box.app-left .qr-box {
        margin-left: auto;
      }
      .download-app-popup .modal-header {
        padding: 0;
        padding-top: 0px;
      }
      .app-border-block {
        margin-bottom: 10px;
      }
      .download-app-popup .modal-header .btn-close {        
        font-size: 20px;        
        top: 70px;
        right: 100px;
    }
    .ndis-container {
        width: 70%;
    }

}

/* Responsive-css Start  */

@media screen and (min-device-width: 100px) and (max-device-width: 768px) {        
    body .download-app-popup .app-link-content {
        padding: 0 10px;
    }
    .home-banner-imgs {
      height: 220vh !important;
    }
    .banner-hero .block-banner .form-find .box-industry .form-input {
      padding-left: 40px;
    }
    .mobile-header-wrapper-styles.mobile-header-active {
      position: fixed;
      top: 0px;
      width: 100%;
      min-height: 100vh;
      bottom: 0;
      right: 0;    
      transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
      background-color: #ffffff;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
      z-index: 998;
  }
    .mobile-header-active {
      visibility: visible;
      opacity: 1;
      transform: translate(0, 0);
  }
  .mobile-header-wrapper-inner {
    padding: 0px 0px 30px;
    height: 100%;  
    overflow-y: auto;
    overflow-x: hidden;
  }
  .mobile-header-content-area {
    padding: 30px;
  }
  .mobile-header-border {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }
  .mobile-search form input {
    border: 0;
    background-color: transparent;
    width: 100%;
    height: 43px;
    border-radius: 4px;
    box-shadow: none;
    padding-left: 20px;
    font-size: 14px;
  }
  .mobile-menu li {
    display: block;
    position: relative;
    padding: 13px 0;
  }
  .mobile-menu li a {
    font-size: 16px;
    line-height: 1;
    text-transform: capitalize;
    font-weight: 500;
    position: relative;
    display: inline-block;
    color: #05264E;
    transition-duration: 0.2s;
  }
  .find-giver-chat-form {
    width: 100%;
  }
  
  
  }
  
  /* Responsive-css End 31477 */

@media screen and (min-device-width: 100px) and (max-device-width: 768px) { 
    .sticky-bar.stick {
        position: relative;
    }
    .main-header.top-header {
        justify-content: end;
    }
    .burger-icon {
        top: 2px;
        right: auto;
        left: 15px;
    }
    .burger-icon.burger-close {
        left: auto;
        right: 15px;
    }
    .banner-hero .block-banner .form-find form {        
        place-items: center;
    }
    .banner-hero .block-banner .form-find .box-industry {
        max-width: 100%;
    }
    .banner-hero .block-banner .form-find .box-industry .select2 {
        max-width: 85%;
        border: 1px solid #1a0f0940;        
        margin: 15px auto 25px;
    }
    .banner-hero .block-banner .form-find .box-location {
        width: 85%;
        margin: auto;
    }
    .banner-hero .block-banner .form-find .form-input {
        border: 1px solid #1a0f0940;
        padding-left: 20px;
        margin-bottom: 25px;
    }
    .range-km01 {
        width: 90%;
        padding-left: 15px;
    }
    .home-sec08 .box-we-hiring {
        padding: 10px 26px;
    }

    .block-title {
        font-size: 12mm;
        line-height: 1;
    }
    .block-subTitle {
        font-size: 6mm;
    }    

    .services-view01 .box-border-single .col-lg-8.col-md-12 h3 {
        margin-bottom: 10px;
    }

    .about-left-div {
        display: block!important;
    }
    .about-tabs-div .nav-tabs {
        max-width: 100%!important;
        min-width: 100%!important;
        display: ruby-text; 
        border-bottom: 0 !important;       
    }
    .about-tabs-div .nav-tabs .nav-link {
        width: max-content !important;
        margin: 0;
    }
    .about-tabs-div .tab-content {
        border-left: 0px solid #eee;
        padding-left: 0px;
        margin-top: 25px;
    }

    .filter-div01 {
        display: none;
    }
    .space-divided01 {
        display: none;
    }
    .giver-view-profile {
        display: block;
    }
    .giver-view-profile .giver-view-info {
        margin-top: 10px;
    }
    .check-available-btn-top {
        display: block;
    }
    .book-now-btn-sec01 p {
        display: block;
        margin-top: 15px;
    }
    .check-available-btn-top p {
        text-align: left!important;
        margin-top: 10px!important;
    }
    .single-giver-ads-section {
        display: none;
    }
    .view-care-givers .giver-view-info .badge {        
        display: block;
        width: fit-content;
        margin-top: 5px;
    }
    .author-bio .author-image {
        display: block;
    }
    .author-bio .author-image p {
        margin-top: 5px;
    }
    .view-care-givers .giver-view-info .giver-top-box-info {
        display: block;
    }
    .download-app-popup .app-scanner-box.app-left .qr-box {
        margin-left: auto;
    }
    .download-app-popup .app-scanner-box .qr-box {
        width: 30vh;
        padding: 18px 10px;
    }

}